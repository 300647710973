body {
  margin: 0;
  font-family: 'Public Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4b465c;
}
input::placeholder {
  font-size: 14px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #FFF;
}
.currency-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 8.5px 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.currency-input:active {
  border: #3498db;
}

/* .currency-input:hover {
  border: 1px solid #000;
} */

input:disabled {
  background-color: #eee;
  border: 1px solid #ccc;
}
button:disabled {
  background-color: #ccc !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-helper-strong {
  font-size: 14px;
  min-width: 20%;
}
.big-form-helper-strong {
  font-size: 14px;
  width: 60%;
}

.radio-group {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

.underline {
  text-decoration: underline;
}
